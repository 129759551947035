import { useKeenSlider } from 'keen-slider/react'

import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

export function useKeenSliderRefs({
  slidesNum,
  interactionTitle,
  initialSlide = 0,
  spacing = undefined,
  slidesPerView = undefined,
  ...overrides
}) {
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: initialSlide,
    range: { align: true },
    mode: 'free-snap',
    ...overrides,
    slides: {
      number: slidesNum,
      ...(spacing ? { spacing } : {}),
      ...(slidesPerView ? { perView: slidesPerView } : {})
    }
  }, [keenSliderTrackingPlugin(interactionTitle)])

  React.useEffect(
    () => { instanceRef.current.update() },
    [instanceRef]
  )

  return { sliderRef, instanceRef }
}

function keenSliderTrackingPlugin(title) {
  return s => {
    s.on('slideChanged', () => { trackSliderInteraction(s.track.details.rel, 'dragged') })
    s.on('dragStarted', () => { trackSliderInteraction(s.track.details.rel, 'drag-started') })
    s.on('dragEnded', () => { trackSliderInteraction(s.track.details.rel, 'drag-ended') })
  }

  function trackSliderInteraction(index, action) {
    trackInteraction({ title, action, type: 'slided', index })
  }
}
